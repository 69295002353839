import {
  AlignContent,
  AlignItems,
  CSSRulesFunction,
  JustifyContent,
  JustifyItems,
  ResponsiveValue,
  SpaceScale,
  useTheme,
} from "../theme";

export interface XYGridProps {
  /**
   * For help on this, [`grid-template-areas` on MDN](https://developer.mozilla.org/en-US/docs/Web/CSS/width)
   */
  width?: ResponsiveValue<React.CSSProperties["width"]>;

  /**
   * For help on this, [`height` on MDN](https://developer.mozilla.org/en-US/docs/Web/CSS/height)
   */
  height?: ResponsiveValue<React.CSSProperties["height"]>;

  /**
   * For help on this, [`grid-template-areas` on MDN](https://developer.mozilla.org/en-US/docs/Web/CSS/grid-template-areas)
   */
  templateAreas?: ResponsiveValue<React.CSSProperties["gridTemplateAreas"]>;

  /**
   * For help on this, [`grid-template-columns` on MDN](https://developer.mozilla.org/en-US/docs/Web/CSS/grid-template-columns)
   */
  templateColumns?: ResponsiveValue<React.CSSProperties["gridTemplateColumns"]>;

  /**
   * For help on this, [`grid-template-rows` on MDN](https://developer.mozilla.org/en-US/docs/Web/CSS/grid-template-rows)
   */
  templateRows?: ResponsiveValue<React.CSSProperties["gridTemplateRows"]>;

  /**
   * For help on this, [`grid-auto-columns` on MDN](https://developer.mozilla.org/en-US/docs/Web/CSS/grid-auto-columns)
   */
  autoColumns?: ResponsiveValue<React.CSSProperties["gridAutoColumns"]>;

  /**
   * For help on this, [`grid-auto-rows` on MDN](https://developer.mozilla.org/en-US/docs/Web/CSS/grid-auto-rows)
   */
  autoRows?: ResponsiveValue<React.CSSProperties["gridAutoRows"]>;

  /**
   * For help on this, [`grid-auto-flow` on MDN](https://developer.mozilla.org/en-US/docs/Web/CSS/grid-auto-flow)
   */
  autoFlow?: ResponsiveValue<React.CSSProperties["gridAutoFlow"]>;

  /**
   * For help on this, [`column-gap` on MDN](https://developer.mozilla.org/en-US/docs/Web/CSS/column-gap)
   */
  columnGap?: ResponsiveValue<SpaceScale>;

  /**
   * For help on this, [`row-gap` on MDN](https://developer.mozilla.org/en-US/docs/Web/CSS/row-gap)
   */
  rowGap?: ResponsiveValue<SpaceScale>;

  /**
   * For help on this, [`align-items` on MDN](https://developer.mozilla.org/en-US/docs/Web/CSS/align-items)
   */
  alignItems?: ResponsiveValue<AlignItems>;

  /**
   * For help on this, [`align-content` on MDN](https://developer.mozilla.org/en-US/docs/Web/CSS/align-content)
   */
  alignContent?: ResponsiveValue<AlignContent>;

  /**
   * For help on this, [`justify-items` on MDN](https://developer.mozilla.org/en-US/docs/Web/CSS/justify-items)
   */
  justifyItems?: ResponsiveValue<JustifyItems>;

  /**
   * For help on this, [`justify-content` on MDN](https://developer.mozilla.org/en-US/docs/Web/CSS/justify-content)
   */
  justifyContent?: ResponsiveValue<JustifyContent>;
  /**
   * For help on this, [`min-height` on MDN](https://developer.mozilla.org/en-US/docs/Web/CSS/min-height)
   */
  minHeight?: ResponsiveValue<React.CSSProperties["height"]>;
  children: React.ReactNode;
}

const gridStyle: CSSRulesFunction<XYGridProps> = (theme, props) => {
  return [
    { display: "grid" },
    theme.responsive(props.width, (v) => ({ width: v })),
    theme.responsive(props.height, (v) => ({ height: v })),
    theme.responsive(props.templateAreas, (v) => ({ gridTemplateAreas: v })),
    theme.responsive(props.templateColumns, (v) => ({
      gridTemplateColumns: v,
    })),
    theme.responsive(props.templateRows, (v) => ({ gridTemplateRows: v })),
    theme.responsive(props.autoColumns, (v) => ({ gridAutoColumns: v })),
    theme.responsive(props.autoRows, (v) => ({ gridAutoRows: v })),
    theme.responsive(props.autoFlow, (v) => ({ gridAutoFlow: v })),
    theme.responsive(props.columnGap, (v) => ({
      gridColumnGap: theme.spacing(v),
    })),
    theme.responsive(props.rowGap, (v) => ({ rowGap: theme.spacing(v) })),
    theme.responsive(props.alignItems, (v) => ({ alignItems: v })),
    theme.responsive(props.justifyItems, (v) => ({ justifyItems: v })),
    theme.responsive(props.justifyContent, (v) => ({ justifyContent: v })),
    theme.responsive(props.minHeight, (v) => ({ minHeight: v })),
  ];
};

const XYGrid: React.FC<XYGridProps> = (props) => {
  const { theme } = useTheme();

  return <div css={gridStyle(theme, props)}>{props.children}</div>;
};

export default XYGrid;
