import { useUserShowSelf } from "@gocardless/api/dashboard/user";
import { useUserShowSelf as useStaffUserShowSelf } from "@gocardless/api/staff/user";
import { UsersScope } from "@gocardless/api/dashboard/types";

import { useMultiAccountFeature } from "src/libraries/organisation/multi-accounts/hooks";
import { useOrganisation } from "src/libraries/organisation/hooks";

export const useUserDashboardPreference = () => {
  const { data } = useUserShowSelf();
  if (data === undefined) {
    return undefined;
  }
  if (data.users?.dashboard_preferences === undefined) {
    return null;
  }
  return data.users.dashboard_preferences;
};

export const useUser = () => {
  const { data: userData } = useUserShowSelf();

  return userData?.users;
};

export const useIsAdmin = () => {
  const user = useUser();
  return user?.scope === UsersScope.Admin;
};

export const useIsReadOnly = () => {
  const user = useUser();
  return user?.scope === UsersScope.ReadOnly;
};

export const useIsGCAdmin = () => {
  const { data: userData } = useStaffUserShowSelf();
  const user = userData?.users;

  return user?.is_admin || false;
};

export const useIsImpersonation = () => {
  const organisation = useOrganisation();
  const isGCAdmin = useIsGCAdmin();
  const { data: userData } = useUserShowSelf();

  return (
    isGCAdmin &&
    organisation &&
    organisation?.id !== userData?.users?.links?.organisation
  );
};

export const useIsMerchantImpersonation = () => {
  const { hasMultiAccountFeature } = useMultiAccountFeature();
  const currentOrganisation = useOrganisation();
  const { data: userData } = useUserShowSelf();

  const isViewingDifferentOrganisation =
    hasMultiAccountFeature &&
    userData &&
    currentOrganisation &&
    currentOrganisation.id !== userData?.users?.organisation_details?.id;

  return {
    isViewingDifferentOrganisation,
    currentOrganisationId: currentOrganisation?.id,
  };
};
