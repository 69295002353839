export enum OptimizelyFlag {
  MEW_PAYOUT_RECONCILIATION = "mew_payout_reconciliation",
  SINGUP_ANNUAL_REVENUE = "signup_annual_revenue",
  NA_MANUAL_UPLOAD_BANK_DOCUMENTS = "na_manual_upload_bank_documents",
  TURBO_GROWTH_SKIP_STEPS = "turbo_growth__skip_steps",
  TURBO_XERO_PARTNER_EXPERIENCE_THE_VALUE = "turbo_growth__xero_partner_experience_the_value",
  TURBO_GROWTH_SETUP_PAYMENT_VOLUMES = "turbo_growth__setup_payment_volumes",
  TURBO_GROWTH_INVOICES = "turbo_growth__invoice_fake_door",
  TURBO_GROWTH_EASY_PAYMENT_DECISION = "turbo_growth__easy_payment_decision",
  SUBSCRIPTION_TEMPLATES_NAMING = "subscription_templates_naming",
  SHARE_WITH_CONFIDENCE_EXPERIMENT = "turbo_growth__share_with_confidence",
  REFERRAL_SCHEME_V2 = "turbo_growth__referral_scheme_v2",
  TURBO_GROWTH_COLLECTIONS_PRE_VERIFICATION_TOGGLE = "turbo_growth__collections_pre_verification_toggle",
  SPARK_SIGNUP_OPTIMISATION = "spark__signup_optimisation",
  SPARK_SIGNUP_OPTIMISATION_V2 = "spark__signup_optimisation_v2",
  SPARK_CHOOSE_REFERRAL_REWARD_V1 = "spark__choose_referral_reward_v1",
  SPARK_EXPERIENCE_PRODUCT_V1 = "spark__experience_the_product_v1",
  TURBO_GROWTH_SETUP_FLOW_ACH_UPLIFT = "enable_setup_flow_ach",
  MERCHANT_ENGAGEMENT_REPORTING_V1 = "merchant_engagement__reporting_v1",
  MERCHANT_ENGAGEMENT_UPSELL_IBP = "meng__upsell_ibp",
  MERCHANT_ENGAGEMENT_UPSELL_FASTER_ACH = "meng__upsell_faster_ach",
  MERCHANT_ENGAGEMENT_FUTURE_DATA = "meng__future_data",
  MERCHANT_ENGAGEMENT_SCHEDULED_PLAN_PAUSE = "scheduled_plan_pause",
  MERCHANT_ENGAGEMENT_BULK_CHANGE_V2 = "meng_bulk_change__all_schemes",
  MERCHANT_ENGAGEMENT_MULTI_ACCOUNT_V2 = "meng-multi-account-v2",
  SECUREIT_UPDATED_EXPIRED_PW_LINK = "enable_updated_expired_pw_link",
  BI_ZENDESK_MIGRATION = "bi_zendesk_migration",
  ASAP_PAYMENTS_INSTALMENTS_BANNER_PLACEMENT = "asap_payments__instalments_banner_placement",
  TURBO_GROWTH_KEEP_COLLECTING_CONFIDENTLY = "turbo_growth__keep_collecting_confidently",
  PRICING_V3 = "pricing_v3",
  TURBO_GROWTH_AIS_VERIFICATION_IN_THE_US_V2 = "turbo_growth__ais_verification_in_the_us_v2",
  TURBO_GROWTH_PREACTIVE_MERCHANTS_REPORTING = "turbo_growth__preactive_merchants_reporting",
  TURBO_GROWTH_SKIP_POST_SIGN_UP_VALUE_STEPS = "turbo_growth__skip_post_sign_up_value_steps",
  TURBO_GROWTH_REWARDS_AND_BOOSTS = "turbo_growth__rewards_and_boosts",
  FLEXIBLE_EXPORT_HEADINGS = "flexibleexportheadings",
  ASAP_PAYMENTS_PIPE_EMBED = "asap_payments__pipe_embed",
  ASAP_PAYMENTS_SUGGESTED_ACTIONS = "asap_payments__suggested_actions",
  BI_BRAZE_BANNERS = "bi_braze_banners",
  PAYER_DETAILS = "payer_details",
  ASAP_PAYMENTS_UPDATE_DASHBOARD_BANNER = "asap_payments__update_dashboard_banner",
  ASAP_PAYMENTS_SEARCH_IMPROVEMENTS = "asap_payments__search_improvements",
  ASAP_PAYMENTS_ENABLE_FLEXIBLE_PAYOUTS = "asap_payments__enable_flexible_payouts",
  ASAP_PAYMENTS_REVIEW_RATINGS_TARGET = "asap_payments_review_ratings_target",
  TURBO_GROWTH_SKIP_FPJ_PREVIEW = "turbo_growth__skip_fpj_preview",
  TURBO_GROWTH_FPJ_INSTALMENTS_AND_QR_CODES = "turbo_growth__fpj_instalments_and_qr_codes",
  TURBO_GROWTH_BOOK_OEX_CALL = "turbo_growth__book_oex_call",
  ASAP_PAYMENTS_NOTIFICATIONS_V1 = "asap_payments__notifications_v1",
  TURBO_GROWTH_POST_PAYMENT_INVOICE_TOGGLE = "turbo_growth_post_payment_invoice_toggle",
  MAX_PAYMENT_LIMIT_INCREASE_FORMS = "max_payment_limit_increase_forms",
  SSO_AVAILABLE = "payments_api__sso_available",
  //   Verifications:
  PENNY_TEST_IMPROVEMENTS = "penny_test_improvements",
  US_VERIFICATION_IMPROVEMENTS = "us_verifications_improvements",
  VERIFICATIONS_NEW_POSTCODE_VALIDATOR = "verifications__new_postcode_validator",
  PACKAGE_SELECTION_PHASE_ONE = "verifications__package_selection_phase_1",
  SOCIAL_MEDIA_INPUT = "verifications__social_media_field",
  VERIFICATIONS_UNSUPPORTED_ENTITY_TYPE = "verifications__unsupported_entity_type",
  VERIFICATIONS_IMPROVE_KBIS_UPLOADS = "verifications__improve_kbis_uploads",
  VERIFICATIONS_PARTNER_MERCHANT_ONBOARDING_IMPROVEMENTS = "verifications__partner_merchant_onboarding_improvements",
}

export enum OptimizelyVariant {
  FLAG_ON = "on",
  FLAG_OFF = "off",
  NEW_DESIGN = "new_design",
  NEW_EXPERIENCE = "new_experience",
  EXISTING_EXPERIENCE = "existing_experience",
  CONTROL = "control",
  EMAIL_VERIFICATION = "email_verification",
  PROOF_OF_ADDRESS_DISABLED = "proof_of_address_disabled",
  VARIATION_1 = "variation_1",
  VARIATION_2 = "variation_2",
  VARIATION_3 = "variation_3",
  OPT_IN = "opt_in",
  OPT_OUT = "opt_out",
  FLEXIBLE_PAYOUTS_GA_GROUP_1 = "2025-06-09",
}
