import { useMemo } from "react";
import { SortMode } from "@gocardless/api/dashboard/types";
import { useUserListOrganisationsSelf } from "@gocardless/api/dashboard/user";
import { HTTPError } from "@gocardless/api/utils";

import { useOrganisation } from "src/libraries/organisation/hooks";
import { AccountListOrganisation } from "src/libraries/organisation/multi-accounts/types";

const DEFAULT_MAX_VISIBLE_ORGANISATIONS = 4;

interface useAccountListProps {
  showAllAccounts?: boolean;
  viewBusinessName?: boolean;
  maxVisibleOrganisations?: number;
  sortByAccessCount?: boolean;
}

interface useAccountListReturn {
  accounts: AccountListOrganisation[];
  isLoading: boolean;
  error?: HTTPError;
  hasMoreThanMaxVisibleAccounts: boolean;
}

export const useAccountList = ({
  showAllAccounts = false,
  maxVisibleOrganisations = DEFAULT_MAX_VISIBLE_ORGANISATIONS,
  sortByAccessCount = false,
}: useAccountListProps = {}): useAccountListReturn => {
  const loggedInOrganisation = useOrganisation();
  const {
    data: { list_organisations: organisationsList } = {},
    isLoading,
    error,
  } = useUserListOrganisationsSelf({
    limit: 500,
    sort_mode: sortByAccessCount ? SortMode.AccessCount : SortMode.Default,
  });

  const sortedOrganisations = useMemo(() => {
    if (!organisationsList) return [];

    const loggedInAccount = organisationsList.find(
      (org) => org.id === loggedInOrganisation?.id
    );
    const otherAccounts = organisationsList.filter(
      (org) => org.id !== loggedInOrganisation?.id
    );

    const orderedOrganisations = [loggedInAccount, ...otherAccounts].filter(
      Boolean
    );

    const mappedOrganisations = orderedOrganisations.map((org) => ({
      id: org?.id || "",
      nickname: org?.nickname || "",
      name: org?.name || "",
      isParent: org?.depth === 0,
      isLoggedIn: org?.id === loggedInOrganisation?.id,
    }));

    return showAllAccounts
      ? mappedOrganisations
      : mappedOrganisations.slice(0, maxVisibleOrganisations);
  }, [
    organisationsList,
    loggedInOrganisation,
    showAllAccounts,
    maxVisibleOrganisations,
  ]);

  const hasMoreThanMaxVisibleAccounts =
    (organisationsList?.length || 0) > maxVisibleOrganisations;

  return {
    accounts: sortedOrganisations,
    isLoading,
    error,
    hasMoreThanMaxVisibleAccounts,
  };
};
